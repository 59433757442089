import { MutableSnapshot } from "@geome/recoil"
import { AppConfig } from "@geome/types"
import { downloadLocaleAtom, downloadURLAtom } from "./atoms"

export const initialiseDownloads =
  (config: AppConfig) =>
  ({ set }: MutableSnapshot): void => {
    set(downloadLocaleAtom, config.locale)
    set(downloadURLAtom, `${window.location.origin}${config.baseUrl}/downloads`)
  }
