import React, { ReactElement } from "react"
import { WaypointsSearch } from "@geome/react-components-next/lib/components/search/waypoints"
import { MagnifyingGlassIcon } from "../icons/magnifyingGlass"
import { RecentSearches } from "@geome/react-components-next/lib/components/search/recentSearches"
import { BasicPinIcon as PinIcon } from "../icons/basicPin"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { ChevronIcon } from "@geome/react-components-next/lib/components/icons/chevron"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useChangeAppMode } from "../../hooks/useChangeAppMode"
import { usePublishUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { ShellLocationCompletion } from "../../types"
import { CompletionLocationIcon } from "../search/completionLocationIcon"
import { HistoryIcon } from "../icons/history"

export const DirectionsSearch = (): ReactElement => {
  const setAppMode = useChangeAppMode()
  const [publishSearchClick] = usePublishUserInteraction("search-button", "click")
  const { translate } = useI18n()

  return (
    <BlockScopingContextProvider block="directions-search">
      <CSSCustomPropertyContextProvider properties={{ "--error-colour": "#D51E20" }}>
        <div className="search__wrapper">
          <button
            className="search__back-to-search-button"
            onClick={() => {
              setAppMode("search")
              publishSearchClick()
            }}
          >
            <ChevronIcon strokeWidth={3} direction="left" />
            {translate("directions.buttons.back_to_search")}
          </button>
          <WaypointsSearch
            completionProps={{
              hasGeolocation: true,
              renderPreSearchContent: (onSelect, atom) => (
                <RecentSearches
                  renderIcon={() => <HistoryIcon size={20} />}
                  onSelect={onSelect}
                  selectionIntentAtom={atom}
                />
              ),
            }}
            renderCompletions={{
              completions: {
                google: ({ description }) => (
                  <>
                    <PinIcon />
                    <span>{description}</span>
                  </>
                ),
                locations: (location: ShellLocationCompletion) => (
                  <>
                    <CompletionLocationIcon location={location} />
                    <span>{location.value}</span>
                  </>
                ),
              },
            }}
            renderSearchIcon={() => <MagnifyingGlassIcon />}
          />
        </div>
      </CSSCustomPropertyContextProvider>
    </BlockScopingContextProvider>
  )
}
