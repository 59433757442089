import { useCSSCustomProperty } from "@geome/react-next/lib/hooks/useCSSCustomProperty"
import React, { ReactElement } from "react"

export const PartnerIcon = (): ReactElement => {
  const colour = useCSSCustomProperty("--shell-grey-colour", "#404040")
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        fillRule="evenodd"
        d="M6.31818 10c1.57909 0 2.86364-1.34571 2.86364-3S7.89727 4 6.31818 4 3.45455 5.34571 3.45455 7s1.28454 3 2.86363 3zm7.36362.8571c-.9818 0-2.5118.2915-3.6818.8572-1.17-.5743-2.7-.8572-3.68182-.8572C4.54273 10.8571 1 11.7829 1 13.6429V16h18v-2.3571c0-1.86-3.5427-2.7858-5.3182-2.7858zM2.22727 14.7143v-1.0714c0-.4629 2.09455-1.5 4.09091-1.5s4.09092 1.0371 4.09092 1.5v1.0714H2.22727zm9.40913 0h6.1363v-1.0714c0-.4629-2.0945-1.5-4.0909-1.5-.8673 0-1.7509.1971-2.4709.4542.2618.3086.4255.6515.4255 1.0458v1.0714zM7.95455 7c0-.94286-.73637-1.71429-1.63637-1.71429S4.68182 6.05714 4.68182 7s.73636 1.71429 1.63636 1.71429S7.95455 7.94286 7.95455 7zm5.72725 3c1.5791 0 2.8637-1.34571 2.8637-3s-1.2846-3-2.8637-3-2.8636 1.34571-2.8636 3 1.2845 3 2.8636 3zm1.6364-3c0-.94286-.7364-1.71429-1.6364-1.71429S12.0455 6.05714 12.0455 7s.7363 1.71429 1.6363 1.71429S15.3182 7.94286 15.3182 7z"
        fill={colour}
      />
    </svg>
  )
}
