import { selectorFamily } from "@geome/recoil"
import { downloadLocaleAtom, downloadURLAtom } from "./atoms"

type DownloadURLInitialResponse = { id: string }
type DownloadURLPollResponse = { status: "pending" } | { status: "ready"; url: string }
const MAX_ATTEMPTS = 30
const INTERVAL = 1000

export const GetDownloadURLSelectorFamily = selectorFamily<
  string | null,
  Record<string, string | boolean>
>({
  key: "GetDownloadURLSelectorFamily",
  get:
    (params) =>
    async ({ get }) => {
      const locale = get(downloadLocaleAtom)
      const url = new URL(get(downloadURLAtom))
      let current_attempt = 0

      const { id } = (await (
        await fetch(url, {
          method: "POST",
          body: JSON.stringify({ ...params, locale, client: "fleet" }),
          headers: { "Content-type": "application/json" },
        })
      ).json()) as DownloadURLInitialResponse

      if (!id) throw new Error("Missing download id")

      while (MAX_ATTEMPTS > current_attempt) {
        const response = (await (await fetch(`${url}/${id}`)).json()) as DownloadURLPollResponse
        if (response.status === "ready") return response.url
        await new Promise((resolve) => setTimeout(resolve, INTERVAL))
        current_attempt += 1
      }
      return null
    },
})
