import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useConfig, useI18n, usePublishUserInteraction } from "@geome/react-next"
import { useRecoilCallback } from "@geome/recoil"
import React, { ReactElement, useMemo, useState } from "react"
import { GetDownloadURLSelectorFamily } from "../../recoil/download/selectors"
import { ShellFleetAppConfig } from "../../types"
import { CloseIcon } from "../icons/close"

type DownloadsModalProps = {
  onClose: () => void
}

export const DownloadsModal = ({ onClose }: DownloadsModalProps): ReactElement => {
  const { translate } = useI18n()
  const [downloadType, setDownloadType] = useState<"CSV" | "GPS">("CSV")

  return (
    <div className="downloads__modal">
      <div className="downloads__title">
        <div>{translate("download.title")}</div>
        <ButtonWithIcon
          className="downloads__close-button"
          text={translate("download.close_button") as string}
          textPosition="tooltip"
          icon={<CloseIcon />}
          onClick={onClose}
          name="downloads-close-button"
          autoFocus={true}
        />
      </div>

      <div className="downloads__content">
        <div className="downloads__segment">
          <div>{translate("download.choose_file_type")}</div>
          <div className="downloads__inputs">
            <label>
              {translate("download.file_type.csv")}
              <input
                type="radio"
                value="CSV"
                name="file-type"
                checked={downloadType === "CSV"}
                onChange={(e) => {
                  if (e.target.checked) setDownloadType("CSV")
                }}
              />
            </label>
            <label>
              {translate("download.file_type.gps")}
              <input
                type="radio"
                value="GPS"
                name="file-type"
                checked={downloadType === "GPS"}
                onChange={(e) => {
                  if (e.target.checked) setDownloadType("GPS")
                }}
              />
            </label>
          </div>
        </div>
        {downloadType === "CSV" && <CSVDownload />}
        {downloadType === "GPS" && <GPSDownload />}
      </div>
    </div>
  )
}

const CSVDownload = (): ReactElement => {
  const { translate } = useI18n()
  const downloadOptions = useConfig<ShellFleetAppConfig["download_options"]>(
    "download_options",
    false
  )
  const [partners, setPartners] = useState<boolean>(true)
  const [area, setArea] = useState<string>("ALL")
  const params = useMemo(() => ({ partners, area }), [partners, area])

  return (
    <>
      <div className="downloads__segment">
        {translate("download.gps.select_area")}
        <select
          className="downloads__select"
          value={area}
          onChange={({ target }) => setArea(target.value)}
        >
          <option value="ALL">{"All"}</option>
          {downloadOptions.gps_areas.map(({ id, label }) => (
            <option value={id} key={id}>
              {label}
            </option>
          ))}
        </select>
      </div>

      <div className="downloads__segment">
        <label className="downloads__label">
          {translate("download.csv.partner_networks")}
          <input
            type="checkbox"
            checked={partners}
            onChange={({ target }) => setPartners(target.checked)}
          ></input>
        </label>
      </div>

      <DownloadButton type="CSV" params={params} />
    </>
  )
}

const GPSDownload = (): ReactElement => {
  const { translate } = useI18n()
  const downloadOptions = useConfig<ShellFleetAppConfig["download_options"]>(
    "download_options",
    false
  )
  const [gpsDevice, setGPSDevice] = useState<string>(downloadOptions.gps_devices[0])
  const [area, setArea] = useState<string>("ALL")
  const [partners, setPartners] = useState<boolean>(true)
  const params = useMemo(
    () => ({ output_format: gpsDevice, partners, area }),
    [gpsDevice, partners, area]
  )

  return (
    <>
      <div className="downloads__segment">
        {translate("download.gps.select_device")}
        <select
          className="downloads__select"
          value={gpsDevice}
          onChange={({ target }) => setGPSDevice(target.value)}
        >
          {downloadOptions.gps_devices.map((device) => (
            <option value={device} key={device}>
              {translate(`download.devices.${device}`)}
            </option>
          ))}
        </select>
      </div>

      <div className="downloads__segment">
        {translate("download.gps.select_area")}
        <select
          className="downloads__select"
          value={area}
          onChange={({ target }) => setArea(target.value)}
        >
          <option value="ALL">{"All"}</option>
          {downloadOptions.gps_areas.map(({ id, label }) => (
            <option value={id} key={id}>
              {label}
            </option>
          ))}
        </select>
      </div>

      <div className="downloads__segment">
        <label className="downloads__label">
          {translate("download.csv.partner_networks")}
          <input
            type="checkbox"
            checked={partners}
            onChange={({ target }) => setPartners(target.checked)}
          ></input>
        </label>
      </div>

      <DownloadButton type="GPS" params={params} />
    </>
  )
}

type DownloadButtonProps = {
  type: "GPS" | "CSV"
  params: {
    area: string
    partners: boolean
    output_format?: string
  }
}

const DownloadButton = ({ type, params }: DownloadButtonProps): ReactElement => {
  const [status, setStatus] = useState<"unclicked" | "pending" | "error">("unclicked")
  const [publishUserInteraction] = usePublishUserInteraction("download-button", "click")
  const { translate } = useI18n()

  const onClick = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        publishUserInteraction({ fileType: type, ...params })
        setStatus("pending")
        const url = await snapshot.getPromise(GetDownloadURLSelectorFamily(params))
        if (url === null) {
          setStatus("error")
          return
        }
        window.location.href = url
        setStatus("unclicked")
      },
    [setStatus, params]
  )

  return (
    <button
      className={`downloads__button downloads__button--${status}`}
      disabled={status === "pending"}
      onClick={onClick}
    >
      {translate(status === "pending" ? "download.button_downloading" : "download.button")}
    </button>
  )
}
