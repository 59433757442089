import React from "react"
import { Search as GenericSearch } from "@geome/react-components-next/lib/components/search/search"
import { MagnifyingGlassIcon } from "../icons/magnifyingGlass"
import { RecentSearches } from "@geome/react-components-next/lib/components/search/recentSearches"
import { BasicPinIcon as PinIcon } from "../icons/basicPin"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { SmallDirectionsIcon } from "../icons/smallDirections"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useChangeAppMode } from "../../hooks/useChangeAppMode"
import { usePublishUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { CompletionLocationIcon } from "./completionLocationIcon"
import { ShellLocationCompletion } from "../../types"
import { useSelectedPlaceAsDestination } from "@geome/recoil/dist/feature/directions/hooks"
import { HistoryIcon } from "../icons/history"

export const Search = (): JSX.Element => {
  const setAppMode = useChangeAppMode()
  const [publishDirectionsClick] = usePublishUserInteraction("directions-button", "click")
  const { translate } = useI18n()

  const setDirectionsToPlace = useSelectedPlaceAsDestination()

  return (
    <BlockScopingContextProvider block="search">
      <CSSCustomPropertyContextProvider properties={{ "--error-colour": "#D51E20" }}>
        <div className="search__wrapper">
          <GenericSearch
            completionProps={{
              captureRecentSearches: true,
              hasGeolocation: true,
              renderPreSearchContent: (onSelect, atom) => (
                <RecentSearches
                  renderIcon={() => <HistoryIcon size={20} />}
                  onSelect={onSelect}
                  selectionIntentAtom={atom}
                />
              ),
            }}
            renderCompletions={{
              completions: {
                google: ({ description }) => (
                  <>
                    <PinIcon />
                    <span>{description}</span>
                  </>
                ),
                locations: (location: ShellLocationCompletion) => (
                  <>
                    <CompletionLocationIcon location={location} />
                    <span>{location.value}</span>
                  </>
                ),
              },
            }}
            renderSearchIcon={() => <MagnifyingGlassIcon />}
            renderAfter={() => (
              <button
                className="search__directions-button"
                title={translate("search.aria.directions_button") as string}
                onClick={() => {
                  setDirectionsToPlace()
                  setAppMode("directions")
                  publishDirectionsClick()
                }}
              >
                <SmallDirectionsIcon />
              </button>
            )}
          />
        </div>
      </CSSCustomPropertyContextProvider>
    </BlockScopingContextProvider>
  )
}
