import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useSetRecoilState } from "@geome/recoil"
import React, { ReactElement } from "react"
import { fullscreenModalAtom } from "../../recoil/atoms"
import { DownloadIcon } from "../icons/download"

export const DownloadButton = (): ReactElement => {
  const { translate } = useI18n()
  const setFullScreenModal = useSetRecoilState(fullscreenModalAtom)

  return (
    <ButtonWithIcon
      className="app__sidebar-downloads-toggle-button"
      onClick={() => setFullScreenModal("downloads")}
      text={translate("filters.button.aria_text") as string}
      textPosition="tooltip"
      icon={<DownloadIcon />}
      name="toggle-download-modal-button"
    />
  )
}
