import React, { ReactElement } from "react"

export const DownloadIcon = (): ReactElement => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4868 6H11.2763V0H4.96053V6H0.75L8.11842 13L15.4868 6ZM0.75 15V17H15.4868V15H0.75Z"
      fill="#404040"
    />
  </svg>
)
