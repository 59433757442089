import React, { ReactElement } from "react"
import { LocationsList as LocationsListBase } from "@geome/react-components-next/lib/components/locationsList"
import { ShellFleetLocation } from "../../types"
import { LocationsListItem } from "@geome/react-components-next/lib/components/locationsList/item"
import { DirectionsButton } from "../directionsButton"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { ResourceEndpoint } from "@geome/types"
import { OpenNow } from "@geome/react-components-next/lib/components/openNow"

export const LocationsList = ({
  endpoint = "nearest_to",
}: {
  endpoint?: ResourceEndpoint
}): ReactElement => (
  <BlockScopingContextProvider block="locations-list">
    <LocationsListBase endpoint={endpoint} resource="locations">
      {(location: ShellFleetLocation) => (
        <LocationsListItem
          key={location.id}
          isSplitButton
          resource="locations"
          location={location}
          renderContent={(_, props) => (
            <div className="locations-list__item-info" {...props} tabIndex={0} role="button">
              <div className="locations-list__item-info-name">{location.name}</div>
              <div className="locations-list__address">{location.formatted_address}</div>
              {location.open_status && (
                <OpenNow
                  openStatus={location.open_status}
                  nextOpenStatusChange={location.next_open_status_change || null}
                  configPath=""
                />
              )}
            </div>
          )}
          renderAfterContent={() => (
            <div className="locations-list__item-directions">
              <DirectionsButton location={location} />
            </div>
          )}
        />
      )}
    </LocationsListBase>
  </BlockScopingContextProvider>
)
