import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import React, { ReactElement, useCallback } from "react"
import { ShellFleetLocation } from "../../types"
import { DirectionsIcon } from "../icons/directions"
import { usePublishUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { useCallOnKeyPress } from "@geome/react-components-next/lib/hooks/callOnKeyPress/useCallOnKeyPress"
import { useDirectionsToLocation } from "@geome/recoil/dist/feature/directions/hooks"
import { useSetRecoilState } from "@geome/recoil"
import { appModeAtom } from "../../recoil/atoms"

export const DirectionsButton = ({ location }: { location: ShellFleetLocation }): ReactElement => {
  const { translate } = useI18n()
  const addLocationToDirections = useDirectionsToLocation()
  const [publishInteraction] = usePublishUserInteraction("directions-link", "click")
  const setAppModeAtom = useSetRecoilState(appModeAtom)

  const onClick = useCallback(() => {
    addLocationToDirections(location)
    setAppModeAtom("directions")
    publishInteraction({ location })
  }, [addLocationToDirections, setAppModeAtom, publishInteraction, location])
  const onKeyPress = useCallOnKeyPress(onClick, ["Enter", " "])

  const distance = location.driving_distance ?? location.distance

  return (
    <div
      className="directions-button"
      role="button"
      onClick={onClick}
      tabIndex={0}
      onKeyPress={onKeyPress}
    >
      <DirectionsIcon />
      {distance !== undefined && (
        <>
          {translate("directions.distance", {
            convert: true,
            distance,
          })}
        </>
      )}
    </div>
  )
}
