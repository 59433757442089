import React, { ReactElement } from "react"
import { ShellLocationCompletion, ShellFleetLocation } from "../../types"
import { useShellRetailIcon } from "@geome/markers/lib/client/shell/retail/useIcon"

type CompletionLocationIconProps = {
  location: ShellLocationCompletion
}

export const CompletionLocationIcon = ({ location }: CompletionLocationIconProps): ReactElement => {
  const { value, id, offers_ev_charging, site_category } = location

  const fakeLocation: ShellFleetLocation = {
    formatted_address: value,
    country_code: "",
    id,
    offers_ev_charging,
    site_category,
    amenities: [],
    inactive: false,
    type: 0,
    brand: "",
    lat: 0,
    lng: 0,
    name: value,
    manning_status: null,
    esi_code: "",
    truck_services: [],
  }

  const icon = useShellRetailIcon(fakeLocation)

  return <img aria-hidden="true" src={icon.url} height={17} width={17} />
}
